import gsap from "gsap";

let mm = gsap.matchMedia();
mm.add("(min-width: 480px)", () => {
   document.body.classList.add('desc-scroll');
});

setTimeout(function () {
   gsap.to(".head-content", {
      y: 0,
      opacity: 1,
      duration: 3,
      ease: "power3.out"
   });
   gsap.to(".menu-btn-link-fix", {
      y: 0,
      opacity: 1,
      duration: 3,
      ease: "power3.out"
   });
   gsap.to(".first-screen", {
      y: 0,
      opacity: 1,
      duration: 3,
      ease: "power3.out"
   });
}, 1000);